import * as THREE from "three"

export class TextureBuilder {
  constructor(data) {
    console.log(data)

    this.controlCanvas = data.canvasDom
    this.controlCtx = this.controlCanvas.getContext("2d")
    this.input = data.inputDom
    this.slider = data.scaleDom
    this.controlCtx.canvas.width = data.size.width
    this.controlCtx.canvas.height = data.size.height
    this.size = data.size

    this.isInited = true

    this.logoImage = new Image()
    this.logoImage.src = data.logoFile

    this.initWidth = 0
    this.initHeight = 0
    this.prevScale = 0

    this.canDrag = false

    // touch
    this.initHypot = 0
    this.prevX = 0
    this.prevY = 0

    this.colors = {
      cover: "#ffffff",
      tube: "#ffffff",
      backdrop: "#ffffff",
    }

    this.areas = {
      logo: [0, 1024, 2048, 2048],
    }

    this.bgColor = "#ffffff"

    this.logoImage.addEventListener(
      "load",
      () => {
        const logoRatio = this.logoImage.height / this.logoImage.width

        if (this.isInited) {
          this.isInited = false
          this.controlData = {
            cx: this.size.width / 2,
            cy: this.size.height / 2,
            width: this.size.width,
            height: this.size.width * logoRatio,
            colors: "blue",
            outLine: false,
          }
        } else {
          this.controlData = {
            cx: this.size.width * 0.75,
            cy: this.size.height * 0.5,
            width: this.size.width / 2,
            height: (this.size.width / 2) * logoRatio,
            colors: "blue",
            outLine: false,
          }
        }

        console.log("image loaded", this.coverData)
        this.renderTexture()
      },
      false
    )

    this.renderScale = 5

    this.renderCanvas = document.createElement("canvas")
    this.renderCtx = this.renderCanvas.getContext("2d")
    this.renderCtx.canvas.width = this.size.width * this.renderScale
    this.renderCtx.canvas.height = this.size.height * this.renderScale

    this.finalRenderCanvas = document.createElement("canvas")
    this.finalRenderCanvas.style.position = "absolute"
    this.finalRenderCanvas.style.top = "-70vh"
    this.finalRenderCanvas.style.left = "-40vw"
    this.finalRenderCanvas.style.border = "1px solid grey"
    this.finalRenderCanvas.style.transform = "scale(0.2)"
    this.finalRenderCtx = this.finalRenderCanvas.getContext("2d")
    this.finalRenderCtx.canvas.width = 2048
    this.finalRenderCtx.canvas.height = 2048

    this.onDrawTextureFun = null

    this.texture = new THREE.CanvasTexture(this.finalRenderCanvas)
    this.texture.flipY = false
    this.texture.encoding = THREE.sRGBEncoding
    this.texture.needsUpdate = true

    this.logoFileReader = new FileReader()
    this.logoFileReader.onload = e => {
      const loadFIle = this.logoFileReader.result
      this.logoImage.src = loadFIle
    }

    // document.body.appendChild(this.finalRenderCanvas);

    // document.body.appendChild(this.coverRenderCanvas);

    this.init()
  }

  init() {
    this.slider.onpointerdown = e => {
      this.initWidth = this.controlData.width
      this.initHeight = this.controlData.height
    }

    this.slider.onpointerup = e => {
      this.slider.value = 50
    }
    this.slider.addEventListener("input", e => {
      console.log(e)

      const scale = Number(e.target.value) / 50
      if (scale !== this.prevScale) {
        this.controlData.width = this.initWidth * scale
        this.controlData.height = this.initHeight * scale
        this.renderTexture()
      }

      //   this.prevScale = scale
    })

    this.controlCanvas.onpointerout = e => {
      e.preventDefault()
      this.pointerout(e)
    }

    this.controlCanvas.onpointerdown = e => {
      // e.preventDefault()
      this.pointerdown(e)
    }

    this.controlCanvas.onpointerup = e => {
      // e.preventDefault()
      this.pointerup(e)
    }
    this.controlCanvas.onmousemove = e => {
      // e.preventDefault()
      this.pointermove(e)
    }

    this.controlCanvas.ontouchstart = e => {
      e.preventDefault()
      this.touchStart(e)
    }
    this.controlCanvas.ontouchmove = e => {
      e.preventDefault()
      this.touchMove(e)
    }
    this.controlCanvas.ontouchend = e => {
      e.preventDefault()
      this.touchEnd(e)
    }

    document.querySelector("#cup-color").addEventListener("input", e => {
      console.log(e)
      const color = e.target.value

      console.log(this.colors)

      this.bgColor = color
      this.conpositeRender()
    })

    document.querySelector("#logo-upload").addEventListener("change", e => {
      // console.log(e);
      this.logoFileReader.readAsDataURL(e.target.files[0])
    })
  }

  touchStart(e) {
    this.prevX = e.targetTouches[0].clientX
    this.prevY = e.targetTouches[0].clientY
  }
  touchMove(e) {
    if (e.targetTouches.length === 1 && this.canDrag) {
      const moveX = e.targetTouches[0].clientX - this.prevX
      const moveY = e.targetTouches[0].clientY - this.prevY
      this.controlData.cx += moveX
      this.controlData.cy += moveY

      this.prevX = e.targetTouches[0].clientX
      this.prevY = e.targetTouches[0].clientY
      this.renderTexture()
    }
  }

  touchEnd(e) {
    this.canDrag = false
    this.initHypot = 0
  }
  pointerdown(e, shape) {
    const startX = parseInt(e.offsetX)
    const startY = parseInt(e.offsetY)
    // const inside = this.isPointerInShape(startX, startY,renderData.shape);

    const inside = this.isPointerInShape(startX, startY, this.controlData)
    this.renderTexture()

    if (inside) {
      this.controlData.outLine = true
      this.canDrag = true

      this.renderTexture()
    }
  }
  pointerup(e, shape) {
    this.canDrag = false
  }
  pointerout(e, shape) {
    this.controlData.outLine = false
    this.renderTexture()
  }

  pointermove(e, shape) {
    const startX = parseInt(e.offsetX)
    const startY = parseInt(e.offsetY)
    const moveX = e.movementX
    const moveY = e.movementY

    const inside = this.isPointerInShape(startX, startY, this.controlData)

    if (this.canDrag) {
      console.log({ moveX, moveY })
      this.controlData.cx += moveX
      this.controlData.cy += moveY
      this.renderTexture()
    }
  }

  isPointerInShape(x, y, shape) {
    const shapeLeft = shape.cx - shape.width / 2
    const shapeRight = shape.cx + shape.width / 2

    const shapeTop = shape.cy - shape.height / 2
    const shapeBottom = shape.cy + shape.height / 2

    if (x > shapeLeft && x < shapeRight && y > shapeTop && y < shapeBottom) {
      // document.body.style.cursor = "move";
      return true
    } else {
      // document.body.style.cursor = "pointer";
    }
  }


  renderTexture() {
    this.renderDom({
      conetex: this.controlCtx,
      renderConetex: this.renderCtx,
      shape: this.controlData,
      domSize: this.size,
      image: this.logoImage,
    })
  }

  renderDom(renderData) {
    const ctx = renderData.conetex
    const renderCtx = renderData.renderConetex
    const data = renderData.shape
    const size = renderData.domSize
    const image = renderData.image
    ctx.clearRect(0, 0, size.width, size.height)

    ctx.drawImage(
      image,
      data.cx - data.width / 2,
      data.cy - data.height / 2,
      data.width,
      data.height
    )

    if (data.outLine) {
      ctx.strokeStyle = data.colors
      ctx.strokeRect(
        data.cx - data.width / 2,
        data.cy - data.height / 2,
        data.width,
        data.height
      )
    }

    renderCtx.clearRect(
      0,
      0,
      size.width * this.renderScale,
      size.height * this.renderScale
    )

    renderCtx.drawImage(
      image,
      (data.cx - data.width / 2) * this.renderScale,
      (data.cy - data.height / 2) * this.renderScale,
      data.width * this.renderScale,
      data.height * this.renderScale
    )

    this.conpositeRender()
  }

  conpositeRender() {
    // this.finalRenderCtx.clearRect(0, 0, 2048, 2048);
    this.finalRenderCtx.fillStyle = this.bgColor
    this.finalRenderCtx.fillRect(0, 0, 2048, 2048)

    this.finalRenderCtx.drawImage(
      this.renderCanvas,
      this.areas.logo[0],
      this.areas.logo[1],
      this.areas.logo[2] - this.areas.logo[0],
      this.areas.logo[3] - this.areas.logo[1]
    )

    if (this.onDrawTextureFun) {
      this.texture.needsUpdate = true
      this.onDrawTextureFun(this.texture)
    }
  }

  onDrawTexture(callBack) {
    this.onDrawTextureFun = callBack
  }
}

import React, { useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";

const ColorPick = (props) => {
  const [color, setColor] = useState(props.color);
  const handleChange = (e) => {
    setColor(e.target.value);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        <Typography variant="h6" noWrap component="div">
          {props.text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <input
            style={{
              cursor: "pointer",
            }}
            id={props.id}
            type="color"
            value={color}
            onChange={handleChange}
          ></input>
          <div>Hex</div>
          <input
            type="text"
            id={props.idInput}
            value={color}
            onChange={handleChange}
            placeholder="hex"
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

ColorPick.propTypes = {};

export default ColorPick;
